.App {
  text-align: center;
}

p, nav {
  font-family: Courier New;
}

a, a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: orange;
}